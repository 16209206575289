import React, { useState, useContext, useRef, useEffect, useMemo } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router'; // <<-- Added
import { cx } from 'cva';
import { Badge, SVG } from '@inkd/ui';
import { CardsAfterDarkContext } from '@web/context/cardsAfterDark';
import { FlagToggleContext } from '@web/context/flagToggle';
import { useCartState } from '@web/utils';

// Translations for the Account Dashboard Navbar
const translations = {
  en: {
    home: 'Home page',
    viewLocations: 'View locations',
    needSupport: 'Need Support?',
    language: 'Language',
    switchLanguage: 'Cambiar a Español',
    privacyPolicyTerms: 'Privacy Policy and Terms of Service',
  },
  es: {
    home: 'Página principal',
    viewLocations: 'Ver ubicaciones',
    needSupport: '¿Necesitas ayuda?',
    language: 'Idioma',
    switchLanguage: 'Switch to English',
    privacyPolicyTerms: 'Política de Privacidad y Términos del Servicio',
  },
};

interface SignInFlowNavbarProps {
  flow: 'sign_in';
}

interface AccountFlowNavbarProps {
  flow: 'account';
  handleEditAccount?: never;
}

type NavbarProps = AccountFlowNavbarProps | SignInFlowNavbarProps;

export function AccountDashboardNavbar({ flow }: NavbarProps) {
  const router = useRouter();
  const isOnDashboard = router.pathname === '/account/dashboard';

  const cardsAfterDarkContext = useContext(CardsAfterDarkContext);
  const languageContext = useContext(FlagToggleContext);

  // Determine the current language based on the flag toggle (default to English)
  const currentLang = languageContext?.isFlagToggle ? 'es' : 'en';

  // Toggle function for switching languages
  const toggleLanguage = () => {
    if (
      languageContext &&
      typeof languageContext.setFlagToggle === 'function'
    ) {
      languageContext.setFlagToggle(!languageContext.isFlagToggle);
    }
  };

  // Track the hamburger menu state
  const [menuOpen, setMenuOpen] = useState(false);

  const isCardsAfterDark = cardsAfterDarkContext?.isCardsAfterDark ?? false;

  // Determine background gradient based on flow or Cards After Dark
  const flowBackgrounds: Record<NavbarProps['flow'], string> = {
    account: 'bg-gradient-blue-to-yellow',
    sign_in: 'bg-gradient-blue-to-yellow',
  };
  const navBackground = isCardsAfterDark
    ? 'bg-gradient-blue-to-purple'
    : flowBackgrounds[flow];

  // Refs for dropdown and hamburger button
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setMenuOpen(false);
      }
    }

    if (menuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen]);

  return (
    <nav
      aria-label='Main navigation'
      className={cx('sticky top-0 z-50', navBackground)}
    >
      <div className='relative mx-auto flex max-w-screen-md items-center justify-between p-3'>
        {/* Left Section: Logo + hamburger OR back button */}
        <div className='flex items-center space-x-2'>
          <NavbarLogo />

          {/* If on /account/dashboard => Show hamburger, else => Show back button */}
          {flow === 'account' && !isOnDashboard ? (
            <BackButton />
          ) : (
            <HamburgerButton
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              buttonRef={buttonRef}
            />
          )}
        </div>

        {/* Right Section: user details or sign in, cart, etc. */}
        <NavbarActions flow={flow} />

        {/* Dropdown (for hamburger) => Left side of parent with 'left-0' */}
        {menuOpen && (
          <div
            ref={dropdownRef}
            className='
              absolute left-0 top-16
              z-50 mt-2
              flex w-48
              flex-col
              space-y-1 rounded-md
              bg-white
              p-2
              shadow-md
            '
          >
            <Link href='/'>{translations[currentLang].home} </Link>
            <a
              href='http://www.inkdgreetings.com/locations'
              target='_blank'
              rel='noopener noreferrer'
              className='block text-sm hover:text-gray-600'
            >
              {translations[currentLang].viewLocations}
            </a>
            <a
              href='https://www.inkdgreetings.com/contact'
              target='_blank'
              rel='noopener noreferrer'
              className='block text-sm hover:text-gray-600'
            >
              {translations[currentLang].needSupport}
            </a>
            <a
              href='https://www.inkdgreetings.com/terms'
              target='_blank'
              rel='noopener noreferrer'
              className='block text-sm hover:text-gray-600'
            >
              {translations[currentLang].privacyPolicyTerms}
            </a>
            {/* Language Toggle Section */}
            <div className='border-t border-gray-200 pt-2'>
              <p className='mb-1 text-xs font-semibold uppercase text-gray-600'>
                {translations[currentLang].language}
              </p>
              <button
                onClick={toggleLanguage}
                className='flex w-full items-center justify-between text-left text-sm hover:text-gray-600'
              >
                <span>{translations[currentLang].switchLanguage}</span>
              </button>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
}

/** Back button => replaces hamburger if not on /account/dashboard */
function BackButton() {
  const router = useRouter();

  return (
    <button
      type='button'
      onClick={() => router.back()}
      className='
        inline-flex
        h-12 w-12
        items-center justify-center
        rounded-lg bg-white p-2
        text-sm text-gray-500
        hover:bg-gray-100
        focus:outline-none focus:ring-2 focus:ring-gray-200
      '
      aria-label='Go back'
    >
      {/* Example arrow icon */}
      <SVG name='ArrowLeft' className='h-16 w-10' />
    </button>
  );
}

/** Hamburger button => only if on /account/dashboard or sign_in flow */
function HamburgerButton({
  setMenuOpen,
  buttonRef,
}: {
  menuOpen: boolean;
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  buttonRef: React.RefObject<HTMLButtonElement>;
}) {
  return (
    <button
      type='button'
      onClick={() => setMenuOpen(prev => !prev)}
      ref={buttonRef}
      className='
        inline-flex 
        h-12 w-12
        items-center justify-center 
        rounded-lg bg-white p-2 
        text-sm text-gray-500
        hover:bg-gray-100
        focus:outline-none focus:ring-2 focus:ring-gray-200
      '
      aria-label='Open menu'
    >
      <svg
        className='h-6 w-6'
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 17 14'
      >
        <path
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          d='M1 1h15M1 7h15M1 13h15'
        />
      </svg>
    </button>
  );
}

function NavbarLogo() {
  return (
    <Link href='/' aria-label='Home screen'>
      <SVG name='Logo' className='h-16 w-10' />
    </Link>
  );
}

function NavbarActions({ flow }: { flow: NavbarProps['flow'] }) {
  return (
    <div className='flex items-center space-x-4'>
      {flow === 'account' && <CustomerDetails />}
      <BrowsingFlow />
      {flow === 'sign_in' && <span aria-hidden='true' />}
    </div>
  );
}

function CustomerDetails() {
  return (
    <Link
      href='/account/edit-details'
      className='relative flex items-center justify-center rounded-full bg-white p-2'
    >
      <span className='sr-only'>Account</span>
      <SVG name='User Icon' className='h-8 w-8' />
    </Link>
  );
}

function BrowsingFlow() {
  const { cart } = useCartState();
  const cartQuantity = useMemo(() => cart.length, [cart]);

  return (
    <Link
      href='/checkout/cart'
      className='relative flex items-center justify-center rounded-full bg-white p-2'
    >
      <span className='sr-only'>Cart</span>
      <SVG name='Cart' className='h-8 w-8' />
      {cartQuantity > 0 && (
        <Badge
          aria-label={`${cartQuantity} item${
            cartQuantity === 1 ? '' : 's'
          } in cart`}
          className='absolute -right-1 -top-1'
        >
          {cartQuantity}
        </Badge>
      )}
    </Link>
  );
}
