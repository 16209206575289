import { type PropsWithChildren } from 'react';
import Head from 'next/head';
import { AccountDashboardNavbar } from '@web/components/account/AccountDashboardNavbar';

export function SignInLayout({
  pageTitle,
  children,
}: PropsWithChildren<{
  pageTitle?: string;
}>) {
  return (
    <>
      <Head>
        <title>
          {pageTitle ? `Ink'd Greetings - ${pageTitle}` : `Ink'd Greetings`}
        </title>
      </Head>

      <AccountDashboardNavbar flow='sign_in' />

      <main className='mx-auto max-w-screen-md'>
        {children ?? <p className='grid place-items-center'></p>}
      </main>
    </>
  );
}
